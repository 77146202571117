










































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import paymentStoreModule from "@/store/modules/payment";
import serviceStoreModule from "@/store/modules/service";
import clientStoreModule from "@/store/modules/client";
import { Business, Client, Payment, Role } from "@/types";
import PaymentDetails from "./PaymentDetails.vue";

const { mapActions: paymentActions, mapGetters: paymentGetters } =
  createNamespacedHelpers("PAYMENT_LIST");
const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICE");

const { mapGetters: clientGetters, mapActions: clientActions } =
  createNamespacedHelpers("CLIENT_P");

export default Vue.extend<any, any, any, any>({
  components: { PaymentDetails },
  name: "PaymentList",
  data: () => ({
    selectedStatus: null,
    selectedClient: null as null | Client,
    payment: undefined as undefined | Payment,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "client",
      },
      { text: "Date", value: "date" },
      { text: "Payment", value: "paymentMethod" },
      // { text: "Service", value: "employee" },
      { text: "Amount", value: "amount" },
      { text: "Charge to Business", value: "vendorCharge" },
      { text: "Status", value: "paymentStatus" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number; itemsPerPage: number },
    showRefundDialog: false,
    showPaymentDetails: false,
    exporting: false,
    selectedMethod: "All",
    selectedService: "",
    phone: "",
  }),
  watch: {
    role() {
      this.fetchPayments("All");
      this.fetchClients();
    },
    options: {
      handler() {
        this.fetchPayments("All");
      },
      deep: true,
    },
    selectedStatus() {
      this.fetchPayments();
    },
  },
  computed: {
    ...paymentGetters(["paymentPage"]),
    ...serviceGetters(["servicePage"]),
    ...clientGetters(["clientPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  mounted() {
    const paymentId = this.$route.query.paymentId as string;
    if (paymentId) this.viewPayment(paymentId);
  },
  methods: {
    ...paymentActions([
      "fetchPaymentList",
      "refundCard",
      "refundMpesa",
      "updatePayment",
      "refundPaystack",
    ]),
    ...clientActions(["fetchClientList"]),
    ...serviceActions(["fetchServiceList"]),
    viewPayment(paymentId: string): void {
      this.payment = this.paymentPage.docs.find(
        (payment: Payment) => payment._id === paymentId
      );
      if (this.payment) this.showPaymentDetails = true;
    },
    fetchPayments(method: string) {
      this.selectedMethod = method;
      const paymentId = this.$route.query.paymentId as string;
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        let limit = this.options.itemsPerPage;
        let page = this.options.page;
        if (limit === -1 || paymentId) limit = 1000000;
        let params = `?businessId=${bid}&page=${page || 1}&limit=${limit}`;
        this.fetchServiceList(params);
        if (method && method != "All") {
          method = method.toLowerCase();
          params += `&paymentMethod=${method}`;
        }
        if (this.selectedStatus && this.selectedStatus != "All Payments") {
          params += `&status=${this.selectedStatus.toLowerCase()}`;
        }
        if (this.selectedClient) {
          params += `&clientId=${this.selectedClient._id}`;
        }
        this.fetchPaymentList(params).then((page) => {
          if (page) {
            if (paymentId) this.viewPayment(paymentId);
          }
        });
      }
    },
    searchByClient() {
      this.fetchPayments("All");
    },
    refund() {
      if (this.payment?.paymentMethod === "card") {
        if (this.payment.source === "paystack") {
          this.refundPaystack({
            paymentId: this.payment._id,
          }).then((refund) => {
            if (refund) {
              this.fetchPayments("All");
              this.showRefundDialog = false;
            }
          });
        } else {
          this.refundCard({
            paymentIntent: this.payment.transactionId,
            // chargeId: this.payment.transactionId,
          }).then((refund) => {
            this.updatePayment({
              id: this.payment?._id,
              refundId: refund.id,
            });
            this.showRefundDialog = false;
          });
        }
      } else if (this.payment?.paymentMethod === "m-pesa") {
        this.refundMpesa({
          transactionId: this.payment.transactionId,
        }).then(() => {
          this.fetchPayments("All");
          this.showRefundDialog = false;
        });
      }
    },
    _export() {
      this.exporting = true;
    },
    fetchClients() {
      if (this.role) {
        const p = `?businessId=${(this.role.business as Business)._id}`;
        this.fetchClientList(p);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYMENT_LIST")) {
      this.$store.registerModule("PAYMENT_LIST", paymentStoreModule);
    }
    if (!this.$store.hasModule("SERVICE")) {
      this.$store.registerModule("SERVICE", serviceStoreModule);
    }
    if (!this.$store.hasModule("CLIENT_P")) {
      this.$store.registerModule("CLIENT_P", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYMENT_LIST");
    this.$store.unregisterModule("SERVICE");
    this.$store.unregisterModule("CLIENT_P");
  },
});
